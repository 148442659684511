<template>
  <!-- 客户设备 -->
  <div class="customer_information">
    <!-- 板块中心区域 -->
    <div class="customerInfor_mid">
      <div class="customerInfor_header">
        <!-- 标题 -->
        <div class="title">
          <img src="../../assets/image/EquipmentStorage_slices/rectangle_31.png" alt="">
          <p v-for="(item,index) in $route.meta" :key="index">{{ item }}</p>
        </div>
        <!-- 选项框和按钮 -->
        <div class="option_btn">
          <div class="options">
            <!-- Element ui--Select 选择器 -->
            <!-- 客户 -->
            <template>
              <span style="margin:0 0.8vw 0 1.92vw">主体</span>
              <el-select v-model="datas.gid" placeholder="全部" style="margin:0 1.45vw 0 0">
                <el-option v-for="item in gidList" :key="item.id" :label="item.gname" :value="item.id">
                </el-option>
              </el-select>
            </template>
            <!-- 设备查询 -->
            <template>
              <span style="margin:0 0.8vw 0 1.92vw">设备查询</span>
              <el-input v-model="datas.stxt" placeholder="设备名称/地址/编号" class="customer_ipt"></el-input>
            </template>
          </div>
          <!-- Element UI--Button按钮 -->
          <div class="btns">
            <!-- 导出 -->
            <el-button type="success" @click="handleExport" style="margin-right: 1vw;background-color: #01C783;">导 出</el-button>
          </div>
        </div>
      </div>
      <!-- 表格区域 -->
      <div class="gateway_form">
        <lists :allDatas='allDatas' @shareSize="shareSize" @sharePage="sharePage" :size="this.datas.size" :loading="loading"
          @shareSelectArr="shareSelectArr"></lists>
      </div>
      <!-- 公司的基本信息 -->
      <div class="firm_inform">

      </div>
    </div>
  </div>
</template>

<script>
// 引入列表区域
import lists from "./components/lists.vue";
import { deviceList, accountManagementList } from "../../utils/api";
import { Message } from "element-ui";
export default {

  components: {
    lists,//表单区域
  },
  data () {
    return {
      value2: '',//网关编号
      value1: '',//客户设备
      datas: {
        gid: '',//公司的id
        page: 1,//当前页码
        size: 10,//当前页展示的数据条数
        stxt: '' // 搜索, 地址id等
      },
      allDatas: {},//所有网关数据和total
      selectArr: [],
      fields: {//表格的数据
        设备编号: 'nmid',
        设备名称: 'mname',
        网关编号: 'nid',
        所属网关: 'nname',
        型号: 'mtype',
        设备类型: 'mism',
      },
      exportName: '',//excel表格名称
      gidList: [],
      loading: true
    };
  },
  beforeMount () {
    this.getGidList()
  },
  mounted () {
    this.getList()
  },
  watch: {
    datas: {
      handler: function (val, oldVal) {
        this.getList()
      },
      deep: true
    }
  },
  methods: {
    handleExport() { // 数据导出
      if(this.selectArr.length === 0) return this.$message.warning('请选择要导出的数据')
      const labels = Object.keys(this.fields)
      const keys = Object.values(this.fields)
      const download = (list) => {
          this.$exportXlsx(
              labels,
              keys,
              list,
              '房间明细',
          );
      };
      download(this.selectArr);
    },
    shareSelectArr (arr) {
      let list = [];
      arr.forEach(item => {
        list.push({
          nmid: item.nmid,
          mname: item.mname,
          nid: item.nid,
          nname: item.nname,
          mtype: this.$netSeries(item.nid),
          mism: item.mism == '2' ? '微断' : item.msim == '3' ? '塑壳' : '未知'
        })
      })
      this.selectArr = [...list]
    },
    // 每页展示的数据条数
    shareSize (size) {
      this.datas.size = size;
    },
    //当前页
    sharePage (page) {
      this.datas.page = page;
    },
    // 获取设备列表
    getList () {
      this.loading = true;
      deviceList(this.datas).then(res => {
        if (res.code == 1) {
          //查询成功
          this.allDatas = res.data;
        } else {
          Message.error('数据查询失败')
        }
        this.loading = false;
      }).catch(err => {
        return console.log(err)
      })
    },
    // 客户主体列表
    getGidList () {
      accountManagementList({}).then(res => {
        if (res.code == 1) {
          res.data.data.unshift({id: '', gname: '全部'})
          this.gidList = res.data.data;
        }
      }).catch(err => {
        return console.log(err);
      })
    },
  },
};
</script>

<style  scoped>
.customer_information {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin: -30px 0 0 0;
}
.customer_information .customerInfor_mid {
  width: 100%;
  height: 100%;
  /* margin-top:-5px !important ; */
}
/* 头部--开始 */
.customerInfor_mid .customerInfor_header {
  width: 100%;
  height: 134px;
  background-color: #fff;
}
.customerInfor_header .title {
  display: flex;
  align-items: flex-end;
}
.customerInfor_header .title img {
  width: 6px;
  height: 20px;
  margin: 20px 0 0 20px;
}
.customerInfor_header .title p {
  font-size: 20px;
  font-family: "SHSCN_Bold";
  margin: 1vw 0 0 0.6vw;
}
/* 选项框和按钮 */
.option_btn {
  width: 100%;
  height: 7.2vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.option_btn /deep/ .el-input {
  width: 206px;
  height: 32px;
}
.options {
  height: 7.2vh;
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.option_btn /deep/ .el-input__icon {
  line-height: 1.85vh;
}
.option_btn span {
  font-size: 16px;
  font-family: "SHSCN_Regular";
  color: #171717;
}
/* 单选框--label */
.el-radio /deep/ .el-radio__label {
  font-size: 16px;
  font-family: "SHSCN_Regular";
  color: #171717;
}
.btns {
  margin-top: 25px;
}
/* 头部--结束 */

/* 表格区域--开始 */
.customerInfor_mid .gateway_form {
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin-top: 1vh;
}
/* 表格区域--结束 */

.lists /deep/ .el-pagination {
  align-items: normal;
  margin-top: 14px;
  height: 50px !important;
}
</style>