<template>
  <!-- 客户设备绑定-- 表格区域 -->
  <div class="lists">
    <!-- Element ui -- Form表单 -->
    <template>
      <el-table v-loading="loading" :data="tableDate" style="width: 100%" :row-class-name="tableRowClassName"
        :header-cell-style="{background:'#4888FF'}" :max-height="tableHeight" ref="multipleTable"
        @selection-change="handleSelectionChange" :row-key="getRowkey">
        <!-- 选择 -->
        <el-table-column type="selection" label="选择" width="100" fixed :reserve-selection="true">
        </el-table-column>
        <!-- 设备编号 -->
        <el-table-column prop="nmid" label="设备编号" min-width="120">
        </el-table-column>
        <!-- 设备名称 -->
        <el-table-column prop="mname" label="设备名称" min-width="120">
        </el-table-column>
        <!-- 设备地址 -->
        <el-table-column prop="maddr" label="设备地址" min-width="120">
        </el-table-column>
        <!-- 网关编号 -->
        <el-table-column prop="nid" label="网关编号" min-width="250">
        </el-table-column>
        <!-- 网关名称 -->
        <el-table-column prop="nname" label="所属网关" min-width="200">
        </el-table-column>
        <!-- 型号 -->
        <el-table-column prop="mtype" label="型号">
          <template v-slot="{row}">{{ $netSeries(row.nid) }}</template>
        </el-table-column>
        <!-- 设备状态 -->
        <!-- <el-table-column prop="status" label="设备状态" min-width="200">
          <template slot-scope="scope"> -->
            <!-- 1--激活 0--未激活  -->
            <!-- <div class="applicateStatus" v-if="scope.row.status == 1">
              <img src="../../../assets/image/withDrawalApplication_slices/path_2011.png"
                style="padding:0 3px 0 0;margin: 0 0 0 -12px;" />激活
            </div>
            <div class="applicateStatus" v-else>
              <img src="../../../assets/image/withDrawalApplication_slices/path_2013.png" alt=""
                style="padding:0 3px 0 0;margin: 0 0 0 -12px;" />未激活
            </div>
          </template>
        </el-table-column> -->
        <!-- 设备类型 -->
        <el-table-column prop="mism" label="设备类型" min-width="200">
          <template #default="{row}">{{ row.mism == '2' ? '微断' : row.msim == '3' ? '塑壳' : '未知' }}</template>
        </el-table-column>
        <!-- 退库 -->
        <el-table-column label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-link type="primary" @click="jumpToEquipmentDetail(scope.row)">详情</el-link>
          </template>
        </el-table-column>
          <!-- 空状态 -->
        <div slot="empty" style="text-align:center;">
          <el-empty description="哎呀，你是不是忘记查询啦！" />
        </div>
      </el-table>
    </template>
    <!-- Element ui--Pagination分页 -->
    <template>
      <el-pagination background @current-change="handleCurrentChange" @size-change="handleSizeChange"
        :current-page.sync="page" :page-sizes="sizes" :page-size="size" layout="total, prev, pager, next,sizes,jumper"
        :total="total">
      </el-pagination>
    </template>
  </div>
</template>

<script>
export default {
  props: ['allDatas', 'size', 'loading'],
  components: {

  },

  data () {
    return {
      page: 1,//当前页
      sizes: [10, 20, 30, 50],
      total: 0,//数据总量
      tableDate: [],
      isSelected: [],//被选中的行的数据
      tableHeight: 0
    };
  },
  computed: {
  },
  watch: {
    allDatas (nVal) {
      this.tableDate = nVal.data;
      this.total = nVal.total;
    }
  },
  created() {
    this.tableHeight = document.documentElement.clientHeight - 300;
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.tableHeight = document.documentElement.clientHeight - 300;
    })
  },

  methods: {
    // 列表的多选框的选中
    handleSelectionChange (val) {
      this.isSelected = val;
      this.$emit('shareSelectArr', this.isSelected);
    },
    getRowkey (row) {
      return row.id
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 1) {
        return 'success-row';
      }
      return '';
    },
    // 分页的方法
    // 选择每页展示多少条数据
    handleSizeChange (val) {
      this.$emit('shareSize', val)
    },
    //当前的页数
    handleCurrentChange (val) {
      this.$emit('sharePage', val)
    },
    // 跳转到设备详情
    jumpToEquipmentDetail (inform) {
      this.$router.push({ path: '/equipmentDetail', query: { nmid: inform.nmid, nname: inform.nname }});
    }
  },
};
</script>

<style  scoped>
.lists {
  height: 100%;
  position: relative;
}
.el-table /deep/ .warning-row {
  background: #fff;
}

.el-table /deep/ .success-row {
  background: #f1f3f8;
}
.el-table /deep/ thead {
  font-family: "SHSCN_Regular";
  color: #fff;
  font-weight: normal;
}
.el-table /deep/ .cell {
  font-family: "SHSCN_Regular";
  font-weight: normal;
  font-size: 14px;
}
.lists .el-pagination {
  position: absolute;
  bottom: 100px;
  right: 0;
  height: 60px;
  background-color: #fff;
  font-family: "SHSCN_Regular";
}
.lists /deep/ .el-pagination__jump {
  margin-left: 0;
}
.el-table /deep/ .el-table__cell {
  text-align: center;
  padding: 16px 0;
}
</style>